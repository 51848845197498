import { useLayoutEffect, useState } from 'react';

const usePostScroll = (point = 50) => {
  const [y, setY] = useState(window.scrollY);
  const [scrollState, setScrollState] = useState<'up' | 'down' | ''>('');

  const handleNavigation = (e: any) => {
    const window = e.currentTarget;
    if (y > window.pageYOffset && y > point) {
      setScrollState('up');
    } else if (y < window.pageYOffset && y > point) {
      setScrollState('down');
    }
    setY(window.scrollY);
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [y]);

  return scrollState;
};

export default usePostScroll;
