import { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import Portal from '@components/portal';
import WelcomeSpecialGiftImg from '@assets/images/modal/welcomeSpecialGift.png';
import { COLORS } from '@styles/constants/_colors';
import { useNavigate } from 'react-router-dom';

interface Props {
  title: string;
  onClose: () => void;
}

const WelcomeSpecialGiftModal = ({ title, onClose }: Props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsOpen(true), 100);

    return () => clearTimeout(timer);
  }, []);

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <Portal>
      <DimmedWrapper onClose={onClose}>
        <ModalField isOpen={isOpen}>
          <Title>{title}</Title>
          <ImageUnit src={WelcomeSpecialGiftImg} />
          <ActionBox>
            <Button
              onClick={() => {
                navigate('/myPage/roomSelect?tab=나의 방');
                onClose();
              }}
            >
              아이템 사용하러 가기
            </Button>
            <CloseText onClick={onClose}>나중에 볼래요</CloseText>
          </ActionBox>
        </ModalField>
      </DimmedWrapper>
    </Portal>
  );
};

export default WelcomeSpecialGiftModal;

const ModalField = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 50%;
  top: ${({ isOpen }) => (isOpen ? 'calc(50% - 20px)' : '100%')};
  transform: ${({ isOpen }) => (isOpen ? 'translate(-50%, -50%)' : 'translate(-50%, 100%)')};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 428px;
  padding: 0 30px;
  transition: all 200ms ease;
`;

const Title = styled.p`
  position: absolute;
  top: calc(83 / 375 * 100vw);
  font-family: Cafe24 Ssurround;
  font-size: 13px;
  font-weight: 700;
  line-height: 14.4px;
  letter-spacing: -0.4px;
  color: ${COLORS.WHITE};
`;

const ImageUnit = styled.img`
  width: 100%;
  object-fit: contain;
`;

const ActionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0 30px 26px;
  background-color: #825a23;
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
`;

const Button = styled.button`
  background-color: ${COLORS.BLACK};
  border-radius: 120px;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cafe24 Ssurround;
  font-size: 15px;
  font-weight: 700;
  line-height: 16.8px;
  color: ${COLORS.WHITE};
`;

const CloseText = styled.p`
  font-family: Noto Sans KR;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  letter-spacing: -0.5px;
  color: ${COLORS.WHITE};
  margin-top: 18px;
`;
