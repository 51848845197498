import dayjs, { ManipulateType } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { MealSaveTimeState } from '../types/meal';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ko';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('ko');
// dayjs.tz('Asia/Seoul');

function leftPad(value: number) {
  if (value >= 10) {
    return value;
  }
  return `0${value}`;
}

export function toStringByFormatting(source: Date, delimiter = '-') {
  const year = source.getFullYear();
  const month = leftPad(source.getMonth() + 1);
  const day = leftPad(source.getDate());
  return [year, month, day].join(delimiter);
}

export function convertDayName(date: Date) {
  const days = ['일', '월', '화', '수', '목', '금', '토'];
  return days[date.getDay()];
}

function deleteZero(value: string) {
  if (Number(value) < 10) {
    return value[1];
  }
  return value;
}

export function excludingZeroDateFormat(formattedDate: string) {
  const [year, month, day] = formattedDate.split('-');
  const monthExcludingZero = deleteZero(month);
  const dayExcludingZero = deleteZero(day);
  return `${monthExcludingZero}.${dayExcludingZero}`;
}

export function calculatedAddedTime(date: string, time: MealSaveTimeState, addingTime: number) {
  const pureDate = dayjs(date).tz('Asia/Seoul');
  const nextDate = dayjs(date).tz('Asia/Seoul').add(1, 'day');
  const hour =
    time.time === 0 && time.hour === '12'
      ? 0
      : time.time === 1 && time.hour !== '12'
      ? Number(time.hour) + 12
      : time.hour;
  const minute = time.minute;
  let translatedTime = '';
  let translatedDate = '';
  const addedTime = Number(hour) + addingTime;
  // console.log(date, pureDate.date(), nextDate.date(), addedTime, addingTime)
  if (addedTime >= 24) {
    translatedDate = `${nextDate.month() + 1}월 ${nextDate.date()}일`;
  } else {
    translatedDate = `${pureDate.month() + 1}월 ${pureDate.date()}일`;
  }

  if (addedTime < 12) {
    translatedTime = `오전 ${addedTime === 0 ? 12 : addedTime}:${minute}`;
  } else if (addedTime === 12) {
    translatedTime = `오후 12:${minute}`;
  } else if (addedTime > 12 && addedTime < 24) {
    translatedTime = `오후 ${addedTime - 12}:${minute}`;
  } else if (addedTime - 24 === 0) {
    translatedTime = `오전 12:${minute}`;
  } else if (addedTime - 24 > 0 && addedTime - 24 < 12) {
    translatedTime = `오전 ${addedTime - 24}:${minute}`;
  } else if (addedTime) {
    translatedTime = `오후 ${addedTime - 24}:${minute}`;
  }

  return {
    translatedDate,
    translatedTime,
  };
}

export function calculatedAddedTimeForRequest(
  date: string,
  time: MealSaveTimeState,
  adddingHour: number,
  addingMinute?: number,
) {
  const hour =
    time.time === 0 && time.hour === '12'
      ? 0
      : time.time === 1 && time.hour !== '12'
      ? Number(time.hour) + 12
      : time.hour;
  const minute = time.minute;
  const translatedDate = dayjs(`${date} ${hour}:${minute}`)
    .tz('Asia/Seoul')
    .add(adddingHour ? adddingHour : 0, 'hour')
    .add(addingMinute ? addingMinute : 0, 'minute')
    .format('YYYY-MM-DD HH:mm');

  return {
    translatedDate,
  };
}

export function translateTimeWidthStartAndEnd(startDate: string, endDate: string) {
  const startTime = dayjs(startDate).tz('Asia/Seoul');
  const endTime = dayjs(endDate).tz('Asia/Seoul');
  const _startTimeText = startTime.format('M월 D일 a h:mm');
  const startTimeText = _startTimeText.includes('am')
    ? _startTimeText.replace(/am/gi, '오전')
    : _startTimeText.replace(/pm/gi, '오후');
  const _endTimeText = endTime.format('M월 D일 a h:mm');
  const endTimeText = _endTimeText.includes('am')
    ? _endTimeText.replace(/am/gi, '오전')
    : _endTimeText.replace(/pm/gi, '오후');
  const gap = endTime.diff(startTime, 'minute');
  const gapTime = `${padNumber(Math.floor(gap / 60), 2)}:${padNumber(gap % 60, 2)}`;

  return {
    startTimeText,
    endTimeText,
    gapTime,
  };
}

export function reverseTranslatedTime(defaultDate: string) {
  const dayjsDefaultDate = dayjs(defaultDate).tz('Asia/Seoul').format('M월 D일 a h:mm');
  let translatedDate = '';
  if (dayjsDefaultDate.includes('am')) {
    translatedDate = dayjsDefaultDate.replace(/am/gi, '오전');
  } else {
    translatedDate = dayjsDefaultDate.replace(/pm/gi, '오후');
  }

  return translatedDate;
}

export function reverseTranslatedTimeWithAddigTime(
  defaultDate: string,
  hour: string,
  minute: string,
) {
  const dayjsDefaultDate = dayjs(defaultDate)
    .tz('Asia/Seoul')
    .add(Number(hour), 'hour')
    .add(Number(minute), 'minute')
    .format('M월 D일 a h:mm');
  let translatedDate = '';
  if (dayjsDefaultDate.includes('am')) {
    translatedDate = dayjsDefaultDate.replace(/am/gi, '오전');
  } else {
    translatedDate = dayjsDefaultDate.replace(/pm/gi, '오후');
  }

  return translatedDate;
}

export function timeReverseCalculator(initalTime: string, endTime: string) {
  const initialDate = dayjs(initalTime).tz('Asia/Seoul');
  const currentDate = dayjs().tz('Asia/Seoul');
  const endDate = dayjs(endTime).tz('Asia/Seoul');
  const subtractedSeconds = currentDate.diff(initialDate, 'second');
  const fastingSeconds = endDate.diff(initalTime, 'second');
  const fastingTime = endDate.diff(initalTime, 'hour');
  const subtractedDay = endDate.diff(initalTime, 'day');

  return {
    subtractedDay,
    subtractedSeconds,
    fastingSeconds,
    fastingTime,
  };
}

export function startTimeHandler(initialTime: string) {
  const startTimeWidthDayjs = dayjs(initialTime).tz('Asia/Seoul');
  return startTimeWidthDayjs.locale('en').format('A h:mm');
}

export const padNumber = (num: number, length: number) => {
  return String(num).padStart(length, '0');
};

export const currentTimeWithDayjs = () => {
  return dayjs().tz('Asia/Seoul').format('YYYY-MM-DD HH:mm');
};

export const formatDateForCalendarHeader = (date?: string) => {
  return dayjs(date).tz('Asia/Seoul').format('YYYY-MM-01');
};

export const setChangeDate = (
  date: string,
  type: ManipulateType,
  addNum: number,
  format = 'YYYY-MM-DD',
) => {
  return dayjs(date).add(addNum, type).format(format);
};

export const translateTextDate = (date: string, format: string) => {
  return (date === '' ? dayjs() : dayjs(date)).format(format);
};

export const chatDateTranslator = (MessageCreatedAt: string) => {
  const translatedTimeForDayjs = MessageCreatedAt.replace(':', ' ');
  const translatedToDayjs = dayjs(translatedTimeForDayjs)
    .tz('Asia/Seoul')
    .locale('ko')
    .set('second', 0);

  return {
    translateDate: translatedToDayjs,
    translatedDateWithFormat: translatedToDayjs
      .format('a h:mm')
      .replace(/am/gi, '오전')
      .replace(/pm/gi, '오후'),
    translatedDateWithDateChangeFormat: translatedToDayjs
      .add(1, 'day')
      .format('YYYY년 M월 D일 dddd'),
  };
};

export const periodTextSpaceHandler = (periodText: string) => {
  if (periodText.includes('~')) {
    const textItems = periodText.split('~');
    const start = textItems[0];
    const end = textItems[textItems.length - 1];
    return [`${start}`, `${end}`].join('\u00A0\u00A0~\u00A0\u00A0');
  } else {
    return periodText;
  }
};

export const translateDate = (time: string) => {
  if (/AM/.test(time)) {
    return time.replace(/AM/gi, '오전');
  } else if (/PM/.test(time)) {
    return time.replace(/PM/gi, '오후');
  } else {
    return time;
  }
};

// 24시간이 지났는지 확인
export const has24HoursPassed = (inputTime: string | dayjs.Dayjs) => {
  const currentTime = dayjs();
  const targetTime = dayjs(inputTime);

  return currentTime.isAfter(targetTime.add(24, 'hour'));
};

// 특정 날짜로부터 오늘까지의 일수를 계산하는 함수
export const dayParticipating = (startDate: string) => {
  const start = dayjs(startDate);
  const today = dayjs();

  // 시작 날짜로부터 오늘까지의 일수를 계산
  return today.diff(start, 'day') + 1;
};
