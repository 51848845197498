import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import CloverCircle from '@assets/images/png/clover-circle.png';
import CloverSpeechBubble from '@assets/images/png/clover-speech-bubble.png';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isWelcomeMissionUser: boolean;
}

const CloverButton = ({ isWelcomeMissionUser, ...props }: Props) => {
  return (
    <Button {...props} isWelcomeMissionUser={isWelcomeMissionUser}>
      <CloverBubbleImg src={CloverSpeechBubble} />
      <CloverImg src={CloverCircle} />
    </Button>
  );
};

export default CloverButton;

const Button = styled.button<{ isWelcomeMissionUser: boolean }>`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: ${({ isWelcomeMissionUser }) => (isWelcomeMissionUser ? '86px' : '20px')};
  right: 20px;
  width: 68px;
  height: 68px;
  z-index: 1;
`;

const CloverBubbleImg = styled.img`
  width: 72px;
  height: 35px;
  position: absolute;
  top: -22px;
  z-index: 10;
`;

const CloverImg = styled.img`
  width: 68px;
  height: 68px;
  animation: swing 1s infinite ease-in;

  @keyframes swing {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(6deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-6deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
