import { getRandomBoxPeriod } from '@apis/mainApi';
import { useQuery } from 'react-query';

const useGetRandomBoxPeriod = (timezone: string, memberIdx: number) => {
  return useQuery({
    queryKey: ['randomBox', memberIdx, timezone],
    queryFn: () => getRandomBoxPeriod(timezone, memberIdx),
    retry: false,
  });
};

export default useGetRandomBoxPeriod;
