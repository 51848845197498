import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@mui/styles';
import Exercise from 'pages/exercise';
import MyChange from 'pages/main/MyChange';
import MyEat from 'pages/main/MyEat';
import { useRecoilState, useRecoilValue } from 'recoil';
import ToastMsg from '../../components/elements/ToastMsg';
import AsyncBoundary from '@components/AsyncBoundary';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import useGetAdContents from '@hooks/queries/useGetAdContents';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { UserProfileForm } from '@models/profile';
import { useScrollDirectionHook } from '@modules/scrollEvent';
import { ACCESS_TOKEN, PROMOTIONKEY } from '@shared/link';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { toastMsgTextState } from '@states/globalAlert';
import { getCookie, setCookie } from '@utils/cookie';
import { lazy, useEffect, useMemo, useState } from 'react';
import MainLayout from './MainLayout';
import TodayNutrients from './TodayNutrients/TodayNutrients';
import MainDateNavigation from './components/MainDateNavigation';
import { has24HoursPassed, setChangeDate } from '@modules/date';
import { useSwipeable } from 'react-swipeable';
import { dateState } from '@states/DateState';
import ScrollUpDownWrapper from '@components/ScrollUpDownWrapper';
import styled from 'styled-components';
import CloverButton from './components/CloverButton';
import WelcomeMissionBanner from './components/WelcomeMissionBanner';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetRandomBoxPeriod from '@hooks/queries/useGetRandomBoxPeriod';
import { timeZoneState } from '@pages/fasting/new/state';
import Spinner from '@components/Spinner';
import useConfigQuery from '@hooks/queries/useConfigQuery';
import useGetWelcomeMissionQuery from '@hooks/queries/useGetWelcomeMissionQuery';
import useModal from '@hooks/useModal';
import usePostMessageReceiver from '@hooks/usePostMessageReceiver';

const MainPopup = lazy(() => import('./elements/MainPopup'));
const PromotionModal = lazy(() => import('@components/modal/PromotionModal'));
const ManualModal = lazy(() => import('@components/modal/ManualModal'));

const ADBRIX_USER_PROPERTY_SYNC = 'ADBRIX_USER_PROPERTY_SYNCED';

export default function Main() {
  const navigate = useNavigate();
  const classes = useStyles();
  const accessToken = getCookie(ACCESS_TOKEN);
  const token = getCookie('is_login');
  const { scrollDirection } = useScrollDirectionHook();
  const { hasModal, openModal, closeModal } = useModal();
  const userPropertySync = getCookie(ADBRIX_USER_PROPERTY_SYNC);
  const { sendAdBrixData, sendUserProperty } = useAdBrix();
  const [date, setDate] = useRecoilState(dateState);
  const timezone = useRecoilValue(timeZoneState);
  const toastMsg = useRecoilValue(toastMsgTextState);
  const [isOpen, setIsOpen] = useState(false);
  const [isSwipeLoading, setIsSwipeLoading] = useState(false);
  const location = useLocation();

  const { data: userInfo, memberIdx, refetch } = useUserInfoQuery();
  const { data: userConfig } = useConfigQuery();
  const { data: welcomeMissionConfig } = useGetWelcomeMissionQuery(
    memberIdx,
    userConfig?.manual_completed === 1,
  );
  const { data: isRandomBoxPeriod } = useGetRandomBoxPeriod(timezone, memberIdx);

  const { data } = useGetAdContents('홈 배너', {
    enabled: getCookie(PROMOTIONKEY) ? has24HoursPassed(getCookie(PROMOTIONKEY) ?? '') : true,
  });

  const isWelcomeMissionUser = useMemo(
    () =>
      userConfig?.manual_completed === 1 &&
      !welcomeMissionConfig?.has_received &&
      (welcomeMissionConfig?.d_day ?? -1) >= 0,
    [userConfig, welcomeMissionConfig],
  );

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (isSwipeLoading) return;
      setDate(setChangeDate(date, 'day', 1));
      setIsSwipeLoading(true);
      setTimeout(() => {
        setIsSwipeLoading(false);
      }, 500);
    },
    onSwipedRight: () => {
      if (isSwipeLoading) return;
      setDate(setChangeDate(date, 'day', -1));
      setIsSwipeLoading(true);
      setTimeout(() => {
        setIsSwipeLoading(false);
      }, 500);
    },
    delta: 150,
  });

  const onCloseBanner = () => {
    setIsOpen(false);
  };

  const handleClickCloverButton = () => {
    navigate('/apple/point?menu=일일미션');
    sendAdBrixData('customEvent', {
      eventKey: 'click_random_reward_quick',
    });
  };

  /**
   * 목표 변경을 하지 않은 기존 유저들의 userProperty를 설정해주기 위한 로직
   */
  const syncUserProperty = () => {
    if (userPropertySync === 'synced') return;

    const adBrixUserProfileData = {
      member_age: userInfo?.member_age ?? 0,
      gender: userInfo?.gender ?? 0,
      start_weight: 0,
      weight_goal: userInfo?.weight_goal ?? 0,
      diet_mode: userInfo?.diet_mode ?? 0,
    };

    sendUserProperty(adBrixUserProfileData as UserProfileForm, userInfo?.member_idx ?? 0);
    setCookie(ADBRIX_USER_PROPERTY_SYNC, 'synced');
  };

  useEffect(() => {
    if (userInfo) {
      sendReactNativeMessage({
        type: 'maintain-login',
        payload: {
          memberIdx: userInfo?.member_idx,
          accessToken: token,
        },
      });
    }
  }, [token, userInfo]);

  useEffect(() => {
    if (accessToken) {
      try {
        (window as any).ReactNativeWebView.postMessage('config');
      } catch (err) {
        //
      }
    }

    syncUserProperty();
    sendAdBrixData('customEvent', {
      eventKey: 'view_home',
    });
  }, []);

  const toastText = (() => {
    switch (toastMsg) {
      case 0:
        return '기록이 완료되었어요';
      case 1:
        return '음식 평가가 완료되었어요';
      case 2:
        return (
          <>
            기능 이용을 위해
            <br />
            먼저 앱을 업데이트 해주세요
          </>
        );
      case 3:
        return '기록이 삭제되었어요';
      case 7:
        return '업데이트가 완료되었어요';
      case 8:
        return '데이터를 불러오지 못했어요 \n 건강 앱 접근 권한을 확인해 주세요';
      default:
        return '기록이 완료되었어요';
    }
  })();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (data && data.length > 0) {
      setIsOpen(true);
    }
  }, [data]);

  useEffect(() => {
    if (userConfig?.manual_completed === 0) {
      openModal(ManualModal, {
        onClose: () => {
          closeModal(ManualModal);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConfig?.manual_completed]);

  useEffect(() => {
    if (location.state?.scrollTo === 'my-eat') {
      setTimeout(() => {
        window.scrollTo({ top: 720, behavior: 'smooth' });
      }, 200);
    }
  }, []);

  usePostMessageReceiver((event: any) => {
    let postMessage;
    if (typeof event.data === 'string') {
      postMessage = JSON.parse(event.data);
    } else {
      postMessage = event.data;
    }

    const { type } = postMessage;
    if (type === 'back-button-press') {
      if (hasModal) return;
      navigate(-1);
    }
  });

  return (
    <MainLayout scrollDirection={scrollDirection} headerContents={<MainDateNavigation />}>
      <Spinner onSpinnerState={isSwipeLoading} zIndex={99999} />
      <AsyncBoundary fallback={<></>}>
        <Grid className={classes.contentsWrapper}>
          {data && data.length > 0 && isOpen && (
            <PromotionModal bannerData={data.slice(0, 3)} closeBanner={onCloseBanner} />
          )}
          <MainPopup />
          <Grid
            {...swipeHandlers}
            style={{
              touchAction: 'pan-y',
            }}
            className={classes.scroll}
          >
            <ToastMsg>{toastText}</ToastMsg>
            <TodayNutrients />
            <MyEat />
            <MyChange />
            <Exercise isWelcomeMissionUser={isWelcomeMissionUser} />
          </Grid>
        </Grid>
      </AsyncBoundary>
      {/* 웰컴 미션 기간 아니면 downY값 88 */}
      <ScrollUpDownWrapper upY={0} downY={isWelcomeMissionUser ? 66 : 0} bottom={66}>
        <Col>
          {isRandomBoxPeriod && (
            <CloverButton
              isWelcomeMissionUser={isWelcomeMissionUser}
              onClick={handleClickCloverButton}
            />
          )}
          {isWelcomeMissionUser && <WelcomeMissionBanner data={welcomeMissionConfig} />}
        </Col>
      </ScrollUpDownWrapper>
    </MainLayout>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    contentsWrapper: {
      paddingTop: 120,
      overflowX: 'hidden',
      backgroundColor: '#f0f0f0',
    },
    scroll: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
      marginBottom: '66px',
    },
    preventScroll: {
      position: 'fixed',
      height: '100%',
      overflow: 'hidden',
    },
  }),
);

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
