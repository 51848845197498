import { useState } from 'react';
import { getExerciseWalks, postExerciseWalks } from '@apis/exercise';
import RefreshArrow from '@assets/svgs/_Icons/arrow/RefreshArrow';
import { WidgetKind } from '@constants/widget';
import useHealthExceptionCase from '@hooks/health/useHealthExceptionCase';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import useToastModal from '@hooks/useToastModal';
import { deviceOs } from '@modules/platformCheck';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { COLORS } from '@styles/constants/_colors';
import SpinnerInBox from 'components/SpinnerInBox';
import useCount from 'pages/main/hooks/useCount';
import usePostMessageReceiver from 'pages/main/hooks/usePostMessageReceiver';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { dateState } from 'states/DateState';
import { Container, Progress, ProgressBar } from './styles';
import styled from 'styled-components';
import useModal from '@hooks/useModal';
import WalkingModal from './WalkingModal';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import { WalkingBurning } from '@assets/svgs';
import WalkingRadioOffImg from '@assets/images/png/walkingRadioOff.png';
import WalkingRadioOnImg from '@assets/images/png/walkingRadioOn.png';
import Popup from '@components/elements/Popup/Popup';
import { setUserConfig } from '@apis/profileApi';

const SHOES_UNDER_100PERCENT_URL =
  'https://dw543otwhx7wx.cloudfront.net/main/main4-walkingShoesWithGrass.png';

const SHOES_OVER_100PERCENT_URL =
  'https://dw543otwhx7wx.cloudfront.net/main/main4-walkingShoesCongratulation.png';

const WalkingDetail = () => {
  const { sendAdBrixData } = useAdBrix();
  const queryClient = useQueryClient();

  const FINAL_VALUE = 10000;
  const widgetKinds = [WidgetKind.DAILY_HEALTH_LOG, WidgetKind.DASHBOARD];
  const [walk, setWalk] = useState(0);
  const [isCalcMode, setIsCalcMode] = useState(false);
  const date = useRecoilValue(dateState);
  const { openModal, closeModal } = useModal();
  const { openModal: openToastModal } = useToastModal();
  const {
    onAndroidHealthNotionPage,
    onUnSyncHealthDataPopup,
    redirectHealthConnectPlayStore,
    checkHealthConnectModalView,
  } = useHealthExceptionCase();

  const count = useCount(walk, 1300);
  const { memberIdx } = useUserInfoQuery();
  const { data, isLoading } = useQuery(
    ['#walks', memberIdx, date],
    () => getExerciseWalks({ memberIdx, createdAt: date }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        setWalk(res.walks || 0);
        setIsCalcMode(res.walkCheck);
      },
    },
  );

  const { mutate: postExerciseWalkMutate } = useMutation(postExerciseWalks, {
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['#walks', memberIdx, date] });
      queryClient.refetchQueries({ queryKey: ['main1', 'nutrient'] });
      widgetKinds.forEach((widgetKind) => {
        sendReactNativeMessage({
          type: 'widgetReloadTimelines',
          payload: { widgetKind },
        });
      });
    },
  });

  const { mutate: postUserConfig } = useMutation({
    mutationFn: () => setUserConfig(memberIdx, { walk: isCalcMode ? 0 : 1 }),
    onSuccess: () => {
      setIsCalcMode((prev) => !prev);
      queryClient.refetchQueries({ queryKey: ['#walks', memberIdx, date] });
      queryClient.refetchQueries({ queryKey: ['main1', 'nutrient'] });

      widgetKinds.forEach((widgetKind) => {
        sendReactNativeMessage({
          type: 'widgetReloadTimelines',
          payload: { widgetKind },
        });
      });
    },
  });

  const onWalkingPostMessage = () => {
    if (deviceOs === 'android') {
      const isCheckedModal = checkHealthConnectModalView();
      if (isCheckedModal !== 'checked') {
        onAndroidHealthNotionPage();
        return;
      }
    }

    sendReactNativeMessage({
      type: 'health',
      payload: { date },
      service: 'getStep',
    });
  };

  usePostMessageReceiver((event: any) => {
    const { type, data } = JSON.parse(event.data);

    if (type === 'healthConnectUnInstall') {
      redirectHealthConnectPlayStore();
      return;
    }

    if (type === 'getStep' && data !== '') {
      setWalk(Math.round(data));
      postExerciseWalkMutate(
        { memberIdx, createdAt: date, walks: Math.round(data) },
        {
          onSuccess: () => {
            sendAdBrixData('customEvent', {
              eventKey: `register_step_record`,
            });
            openToastModal({ children: <p>기록이 완료되었어요</p> });
          },
        },
      );
      return;
    } else if (type === 'getStep' || type === 'iosHealthSyncFail') {
      onUnSyncHealthDataPopup();
      return;
    }
  });

  const onPercent = () => {
    return (walk / FINAL_VALUE) * 100 > 100 ? 100 : Math.floor((walk / FINAL_VALUE) * 100);
  };

  const handleOpenWalkingModal = () => {
    openModal(WalkingModal, {
      walk,
      closeModal: () => {
        closeModal(WalkingModal);
      },
      onSubmit: (value: number) => {
        setWalk(value);
        closeModal(WalkingModal);
        postExerciseWalkMutate(
          { memberIdx, createdAt: date, walks: value },
          {
            onSuccess: () => {
              sendAdBrixData('customEvent', {
                eventKey: `register_step_record`,
              });
              openToastModal({ children: <p>기록이 완료되었어요</p> });
            },
          },
        );
      },
      onDelete: () => {
        setWalk(0);
        postExerciseWalkMutate(
          { memberIdx, createdAt: date, walks: 0 },
          {
            onSuccess: () => {
              openToastModal({ children: <p>삭제가 완료되었어요</p> });
            },
          },
        );
        closeModal(WalkingModal);
      },
    });
  };

  const handleClickToggle = () => {
    openModal(Popup, {
      headText: isCalcMode ? '걸음 칼로리 계산 기능을 끌까요?' : '걸음 칼로리 계산 기능을 켤까요?',
      buttonText1: '아니오',
      buttonText2: '네',
      onClick: () => {
        postUserConfig();
        closeModal(Popup);
      },
      cancel: () => {
        closeModal(Popup);
      },
    });
  };

  if (isLoading) {
    return <SpinnerInBox height={'calc(97.6vw + 93px)'} />;
  }

  return (
    <Container>
      <TitleField>
        <b className="content">{count}</b>
        <Title>걸음</Title>
      </TitleField>

      <ShoesIllustWrapper
        src={onPercent() === 100 ? SHOES_OVER_100PERCENT_URL : SHOES_UNDER_100PERCENT_URL}
      />

      <div className="bottom">
        <ProgressField>
          <div className="info">
            <p>
              만보 걷기<span className="bang">{onPercent()}%</span>
            </p>
            <div className="burning">
              <WalkingBurning />
              <Kcal>
                <span>{!isCalcMode ? '-' : data?.exerciseCalories ?? 0}</span>kcal
              </Kcal>
              {isCalcMode && <KcalText>소모</KcalText>}
            </div>
          </div>
          <Progress>
            <ProgressBar percent={onPercent()} />
          </Progress>
          <BottomField>
            <Button onClick={handleOpenWalkingModal}>{walk > 0 ? '수정하기' : '기록하기'}</Button>
            <SyncHealthAppBox onClick={onWalkingPostMessage}>
              <p>건강 앱에서 불러오기</p>
              <RefreshArrow color={COLORS.PRIMITIVES_KHAKI_500} />
            </SyncHealthAppBox>
          </BottomField>
        </ProgressField>
        <ToggleBox>
          <ToggleImg
            src={isCalcMode ? WalkingRadioOnImg : WalkingRadioOffImg}
            onClick={handleClickToggle}
          />
          <ToggleTitleBox>
            <ToggleTitle>걸음 칼로리 계산하기</ToggleTitle>
          </ToggleTitleBox>
        </ToggleBox>
      </div>
    </Container>
  );
};

export default WalkingDetail;

const ShoesIllustWrapper = styled.img`
  width: 335px;
  height: 192px;
`;

const TitleField = styled.div`
  display: flex;
  align-items: baseline;
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: -0.005em;
  text-align: center;
  margin-left: 4px;
  transform: translateY(-2px);
`;

const BottomField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`;

const SyncHealthAppBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 6px;
  margin-top: 26px;

  color: ${COLORS.PRIMITIVES_KHAKI_500};
  font-family: Noto Sans KR;
  font-size: 13px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.615px;
`;

const Button = styled.button`
  width: 255px;
  height: 60px;
  border-radius: 120px;
  background-color: ${COLORS.PRIMITIVES_KHAKI_450};
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.6px;
  text-align: center;
  color: ${COLORS.WHITE};
  margin-top: 34px;
`;

const ProgressField = styled.div`
  padding: 0px 20px;
`;

const Kcal = styled.p`
  font-family: Campton;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  letter-spacing: -0.5px;
  color: ${COLORS.WHITE};
  margin-left: 8px;

  span {
    margin-right: 2px;
  }
`;

const KcalText = styled.p`
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: -0.5px;
  margin-left: 4px;
  color: ${COLORS.WHITE};
`;

const ToggleBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  margin-bottom: 94px;
`;

const ToggleTitleBox = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleImg = styled.img`
  width: 52px;
  height: 32px;
`;

const ToggleTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: -0.5px;
  color: ${COLORS.WHITE};
  margin-left: 12px;
  transform: translateY(-2px);
`;
