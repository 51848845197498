import axios from 'axios';
import imageCompression from 'browser-image-compression';
import RuntimeError from 'utils/RuntimeError';
import { del, post } from './HttpWebClient/default';

type Path =
  | 'recipe'
  | 'community'
  | 'ketogram'
  | 'profile'
  | 'ketogram-title'
  | 'chat'
  | 'summary'
  | 'group-profile'
  | 'food-report'
  | 'message'
  | 'room';

const REVERT_MINE_TYPE = ['heic', 'HEIC', 'heif', 'HEIF'];

interface UploadJsonType {
  jsonData: object;
  name: string;
  path: Path;
  memberIdx: number;
}

const handleLimitAndSetToast = async (file: File) => {
  if (file.size > 10485760) {
    throw new RuntimeError({
      code: 4000,
      name: 'exceed error',
      message: 'Image limit size exceeded',
    });
  }
};

const getPresignedUrl = async ({
  memberIdx,
  body,
}: {
  memberIdx: number;
  body: { path: Path; count: number; file_format: string; room_id?: string };
}) => {
  const { data } = await post<string[]>(`/v3/members/${memberIdx}/aws-s3/presigned-url`, body);

  return data;
};

const uploadPresignedUrl = async ({ url, blob }: { url: string; blob: File }) => {
  const file = await revertToJpg(blob);

  await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
  });

  return url.split('?')[0];
};

const deleteS3 = async ({ key, memberIdx }: { key: string; memberIdx: number }) => {
  const { data } = await del(`/v3/members/${memberIdx}/aws-s3?key=${key}`);

  return data;
};

const revertToJpg = async (imgFile: File) => {
  let file = imgFile;
  const nameList = file.name.split('.');
  const revertTarget = REVERT_MINE_TYPE.find(
    (mineType) => nameList[nameList.length - 1] === mineType,
  );

  if (revertTarget) {
    const options = {
      fileType: 'image/jpeg', // 변환할 파일 형식
    };
    const compressedFile = await imageCompression(imgFile, options);
    file = new File([compressedFile], file.name.split('.').slice(0, -1).join('.') + '.jpg', {
      type: 'image/jpeg',
      lastModified: new Date().getTime(),
    });
  }

  return file;
};

// ----- JSON -----
const uploadJsonToS3 = async ({ jsonData, name, path, memberIdx }: UploadJsonType) => {
  // JSON 데이터를 Blob으로 변환
  const jsonBlob = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });
  // Blob을 File 객체로 변환
  const jsonFile = new File([jsonBlob], 'json', {
    type: 'application/json',
    lastModified: new Date().getTime(),
  });

  const data = await getPresignedUrl({
    memberIdx,
    body: {
      path,
      count: 1,
      file_format: 'json',
      room_id: name,
    },
  });

  const url = await uploadPresignedUrl({ url: data[0], blob: jsonFile });

  return url;
};

// ----- Data URL to Blob 변환 -----
const dataURLtoBlob = (dataUrl: string) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

// ----- 캔버스 이미지 업로드 -----
const uploadCanvasToS3 = async ({
  dataUrl,
  name,
  path,
  memberIdx,
}: {
  dataUrl: string;
  name: string;
  path: Path;
  memberIdx: number;
}) => {
  // dataUrl을 Blob으로 변환
  const blob = dataURLtoBlob(dataUrl);

  // Blob을 File 객체로 변환
  const file = new File([blob], `${name}.png`, {
    type: 'image/png',
    lastModified: new Date().getTime(),
  });

  const data = await getPresignedUrl({
    memberIdx,
    body: {
      path,
      count: 1,
      file_format: 'png',
      room_id: name,
    },
  });

  const url = await uploadPresignedUrl({ url: data[0], blob: file });

  return url;
};

export {
  getPresignedUrl,
  uploadPresignedUrl,
  deleteS3,
  handleLimitAndSetToast,
  uploadJsonToS3,
  uploadCanvasToS3,
};
