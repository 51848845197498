import React, { useEffect, useMemo, useRef } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { postWelcomeMission } from '@apis/profileApi';
import { CircleCheckEditIcon, CircleCheckStrongIcon } from '@assets/svgs';
import { GiftBoxIcon } from '@assets/svgs/illust';
import WelcomeMissionCheckModal from '@components/modal/WelcomeMissionCheckModal';
import WelcomeSpecialGiftModal from '@components/modal/WelcomeSpecialGiftModal';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import useModal from '@hooks/useModal';
import { WelcomeMissionConfigType } from '@models/profile';
import { timeZoneState } from '@pages/fasting/new/state';
import { COLORS } from '@styles/constants/_colors';
import { getCookie, setCookie } from '@utils/cookie';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import WelcomeMissionCheckImg from '@assets/images/modal/welcomeMissionCheck.png';
import useImagePreloader from '@hooks/useImagePreloader';

interface Props {
  data?: WelcomeMissionConfigType;
}

const WelcomeMissionBanner = ({ data }: Props) => {
  const { sendAdBrixData } = useAdBrix();
  const { openModal, closeModal } = useModal();
  const isImageLoaded = useImagePreloader(WelcomeMissionCheckImg);

  const mutationRef = useRef(false);
  const queryClient = useQueryClient();
  const count = useMemo(() => data?.count ?? 0, [data?.count]);
  const dDay = useMemo(() => data?.d_day ?? 0, [data?.d_day]);
  const isComplete = useMemo(() => count >= 3 && data?.is_available, [count, data?.is_available]);
  const { memberIdx } = useUserInfoQuery();
  const WELCOME_KEY = String(memberIdx) + '_welcomeMissionCheckModal';
  const isFirstOpen = getCookie(WELCOME_KEY);
  const timezone = useRecoilValue(timeZoneState);

  const { mutate: postWelcomeMissionMutate } = useMutation({
    mutationFn: postWelcomeMission,
    onSuccess: () => {
      sendAdBrixData('customEvent', {
        eventKey: `complete_welcome_mission`,
      });
      queryClient.invalidateQueries({ queryKey: ['welcomeMission'] });
      queryClient.invalidateQueries({ queryKey: ['config'] });
      openModal(WelcomeSpecialGiftModal, {
        title: '웰컴 미션 성공',
        onClose: () => {
          closeModal(WelcomeSpecialGiftModal);
        },
      });
    },
    onSettled: () => {
      mutationRef.current = false;
    },
  });

  const handleMutation = () => {
    if (mutationRef.current) return;
    mutationRef.current = true;
    postWelcomeMissionMutate({ memberIdx, timezone });
  };

  const handleOpenWelcomeMissionCheckModal = () => {
    openModal(WelcomeMissionCheckModal, {
      data,
      onClose: () => {
        closeModal(WelcomeMissionCheckModal);
      },
      onSubmit: () => {
        if (count < 3) {
          setTimeout(() => {
            window.scrollTo({ top: 720, behavior: 'smooth' });
          }, 200);
        } else {
          handleMutation();
        }
        closeModal(WelcomeMissionCheckModal);
      },
    });
  };

  useEffect(() => {
    if (!isFirstOpen && data && isImageLoaded) {
      setCookie(WELCOME_KEY, 'true');
      sendAdBrixData('customEvent', {
        eventKey: `view_welcome_mission`,
      });
      handleOpenWelcomeMissionCheckModal();
    }
  }, [data, isImageLoaded]);

  return (
    <Wrapper
      onClick={() => {
        sendAdBrixData('customEvent', {
          eventKey: `click_welcome_mission`,
        });
        handleOpenWelcomeMissionCheckModal();
      }}
    >
      <Left>
        <GiftBoxIcon width={24} height={24} />
        <Title>웰컴 미션 진행 중</Title>
        <DdayText isDday={dDay === 0}>{dDay === 0 ? `D-Day` : `D-${dDay}`}</DdayText>
      </Left>

      {isComplete ? (
        <Button>보상 받기</Button>
      ) : (
        <CheckField>
          {Array.from({ length: 3 }).map((_, index) => {
            const isActive = index < count;

            return (
              <React.Fragment key={index}>
                {isActive ? <CircleCheckStrongIcon /> : <CircleCheckEditIcon />}
              </React.Fragment>
            );
          })}
        </CheckField>
      )}
    </Wrapper>
  );
};

export default WelcomeMissionBanner;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  background-color: ${COLORS.WHITE};
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  padding: 18px 22px 15px;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: ${COLORS.BLACK};
  margin: 0px 5px 0px 6px;
`;

const DdayText = styled.p<{ isDday?: boolean }>`
  font-family: Campton;
  font-size: 14px;
  font-weight: 900;
  line-height: 16.8px;
  letter-spacing: -0.5px;
  color: ${({ isDday }) => (isDday ? COLORS.PRIMITIVES_PINK_400 : COLORS.BLUE)};
  transform: ${({ isDday }) => (isDday ? `translateY(1px)` : `translateY(0px)`)};
`;

const CheckField = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const Button = styled.button`
  border-radius: 120px;
  color: ${COLORS.WHITE};
  background-color: #ff9913;
  width: 86px;
  height: 39px;
  font-family: Cafe24 Ssurround;
  font-size: 13px;
  font-weight: 700;
  line-height: 18.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;
