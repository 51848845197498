export const ACCESS_TOKEN = 'is_login';
export const PROMOTIONKEY = 'PROMOTIONKEY';
export const ACCESS_TOKEN_CHECK = 'refresh';

// env
export const KAKAOURL = process.env.REACT_APP_KAKAO_URL!;
export const NAVERURL = process.env.REACT_APP_NAVER_URL!;
export const APPLEURL = process.env.REACT_APP_APPLE_URL!;
export const BACKURL = process.env.REACT_APP_BACK_URL!;
export const BATTLEURL = process.env.REACT_APP_BATTLE_URL!;
export const GROUPBATTLE = process.env.REACT_APP_GROUP_BATTLE_URL!;
export const MEALURL = process.env.REACT_APP_MEAL_URL!;
export const SEARCHURL = process.env.REACT_APP_SEARCH_URL!;
export const EXERCISE_SEARCH_URL = process.env.REACT_APP_EXERCISE_SEARCH_URL!;
export const CALENDER_DIARY_URL = process.env.REACT_APP_CALENDER_DIARY_URL!;
export const GROUPCHAT = process.env.REACT_APP_GROUP_CHAT_URL!;
export const ENV = process.env.REACT_APP_ENV!;

export const S3_URL = 'https://inout-posting-imgs.s3.ap-northeast-2.amazonaws.com';

export const MOOHWADANG_PROMOTION_LINK = 'https://m.ketotable.kr/event_v_1_0_0/planning_detail/150';

export const NOTION_PAGE_ID = {
  loginInquiry: 'd32c03a623474ab4a2f2f98a22243ba4',
  terms: '54fc8f6960cb41898ec5c4d7c7107e9a',
  privacy: 'cef64d8de9624d98948cc9de68e2e902',
  updateNews: 'befd063985a34c5682e4c4b0821373f6',
  faq: 'a6200bc8bacd41248896655e8106bbef',
  androidHealthConnect: '9a4a1217a3594ae4bf360c6f2e79b86c',
  healthAppAuth: '244fe3695496415282915251b6fed5a2',
  event: '13db797d72c94745bb2430c46a67c185?pvs=4',
};
