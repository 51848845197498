import {
  AnonymityProfile,
  MyPageFirstPageInfo,
  UserConfig,
  UserInfo,
  UserInfoForModifyProfile,
  UserProfileForm,
  WelcomeMissionConfigType,
} from '@models/profile';
import { Token } from '@models/sign';
import { del, get, post } from '@modules/HttpWebClient/default';
import { BoardInfo, ScrapBoardInfo } from '@models/posting';
import { ScrapFeed } from '@models/myPage';
import { ConfigChangeRequest, FoodReview } from '@models/main';
import { ResponseBase } from '@models/response';

export async function setProfile(request: UserProfileForm): Promise<{ result: string }> {
  const url = `/profile`;
  const { data } = await post<{ result: string }>(url, {
    ...request,
  });
  return data;
}

export async function getUserInfo(): Promise<UserInfo> {
  const url = `/auth/check`;
  const { data } = await get<UserInfo>(url);
  return data;
}

export async function getUserInfoForModifyProfile(): Promise<UserInfoForModifyProfile> {
  const url = `/profile/detail`;
  const { data } = await get<UserInfoForModifyProfile>(url);
  return data;
}

export async function getMyProfile(): Promise<MyPageFirstPageInfo> {
  const url = `/profile/activity/displayV2`;
  const { data } = await get<MyPageFirstPageInfo>(url);
  return data;
}

export async function getManageBoardList(memberIdx: number, boardIdx: number) {
  const url = `members/${memberIdx}/boards`;
  const { data } = await get<BoardInfo[]>(url, {
    params: {
      board_idx: boardIdx,
    },
  });
  return data;
}

export async function getManageBoardCommentList(memberIdx: number, boardIdx: number) {
  const url = `members/${memberIdx}/boards/comments`;
  const { data } = await get<BoardInfo[]>(url, {
    params: {
      board_idx: boardIdx,
    },
  });

  return data;
}

export async function getManageScrapList(memberIdx: number, boardBookmarkIdx: number) {
  const url = `members/${memberIdx}/bookmark/boards`;
  const { data } = await get<ScrapBoardInfo[]>(url, {
    params: {
      board_bookmark_idx: boardBookmarkIdx,
    },
  });
  return data;
}

export async function getManageScrapFeedList(ketogramScrapIdx: number) {
  const url = `/profile/feed/scrap/list/${ketogramScrapIdx}`;
  const { data } = await get<ScrapFeed[]>(url);
  return data;
}

export async function getMyFoodReviewList(memberIdx: number, scrollIdx: number) {
  const url = `/members/${memberIdx}/review`;
  const { data } = await get<FoodReview[]>(url, {
    params: {
      scroll_idx: scrollIdx,
    },
  });

  return data;
}

export async function tokenReissue(memberIdx: string): Promise<Token> {
  const url = '/auth/token/resend';
  const { data } = await post<Token>(url, {
    memberIdx,
  });
  return data;
}

export async function getUserConfig(memberIdx: number) {
  const url = `/v2/members/${memberIdx}/config`;
  const { data } = await get<UserConfig>(url);
  return data;
}

export async function setUserConfig(memberIdx: number, request: ConfigChangeRequest) {
  const url = `/v2/members/${memberIdx}/config`;
  const { status } = await post<ResponseBase>(url, request);
  return status === 200;
}

export async function deleteUserConfig(memberIdx: number, deleteKey: keyof ConfigChangeRequest) {
  const url = `/v2/members/${memberIdx}/config/${deleteKey}`;
  const { status } = await del<ResponseBase>(url);
  return status === 200;
}

export async function getAnonymityProfileImages(memberIdx: number) {
  const url = `/v3/members/${memberIdx}/profile-img/boards`;
  const { data } = await get<AnonymityProfile[]>(url);

  return data;
}

// 메뉴얼 완료(튜토리얼 완료) 요청
export async function postManual(memberIdx: number) {
  const url = `/v3/members/${memberIdx}/manual`;
  const { data } = await post(url);
  return data;
}

// 웰컴 미션 진행 상황 조회
export async function getWelcomeMissionConfig(memberIdx: number, timezone: string) {
  const url = `/v3/members/${memberIdx}/missions/welcome/new?timezone=${timezone}`;
  const { data } = await get<WelcomeMissionConfigType>(url);

  return data;
}

// 웰컴 미션 보상 지급
export async function postWelcomeMission({
  memberIdx,
  timezone,
}: {
  memberIdx: number;
  timezone: string;
}) {
  const url = `/v3/members/${memberIdx}/missions/welcome/new?timezone=${timezone}`;
  const { data } = await post(url);
  return data;
}

// 기존 유저 웰컴 미션 보상 지급
export async function postExistingUserWelcomeMission({
  memberIdx,
  timezone,
}: {
  memberIdx: number;
  timezone: string;
}) {
  const url = `/v3/members/${memberIdx}/missions/welcome/existing?timezone=${timezone}`;
  const { data } = await post(url);
  return data;
}
