import { getExerciseMain } from '@apis/exercise';
import ExerciseMainCharacter from '@assets/images/webp/character/exerciseMainCharacter.webp';
import Fire from '@assets/images/icon/FIre';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { COLORS } from '@styles/constants/_colors';
import SpinnerInBox from 'components/SpinnerInBox';
import { useCoordinate } from 'hooks/useCoordinate';
import { CharacterImg, Container, ThemeButton } from 'pages/exercise/ExerciseDiary/styles';
import {
  exerciseBasketListState,
  exerciseFreeFormItemAtom,
  exerciseSearchKeywordAtom,
  exerciseTabAtom,
} from 'pages/exercise/states';
import convertTime from 'pages/exercise/utils/convertTime';
import { useCallback, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { dateState } from 'states/DateState';
import useGetExerciseListQuery from '../hooks/useGetExerciseListQuery';

interface Props {
  isWelcomeMissionUser: boolean;
}
const ExerciseDiary = ({ isWelcomeMissionUser }: Props) => {
  const [date] = useRecoilState(dateState);
  const [ref, inView] = useInView({ triggerOnce: true });
  const { setCoordinate } = useCoordinate();
  const setExerciseTab = useSetRecoilState(exerciseTabAtom);
  const setExerciseSearchKeyword = useSetRecoilState(exerciseSearchKeywordAtom);
  const resetExerciseFreeFormItem = useResetRecoilState(exerciseFreeFormItemAtom);

  const onClickExerciseRecord = useCallback(() => {
    setCoordinate();
    setExerciseTab('빠른 추가');
    setExerciseSearchKeyword('');
    resetExerciseFreeFormItem();
  }, [resetExerciseFreeFormItem, setCoordinate, setExerciseSearchKeyword, setExerciseTab]);

  const { data: userData } = useUserInfoQuery();
  const memberIdx = useMemo(() => userData?.member_idx ?? 0, [userData?.member_idx]);
  const { data: mainInfoData, isLoading: isMainInfoDataLoading } = useQuery(
    ['#exerciseMainInfo', memberIdx, date],
    () => getExerciseMain({ memberIdx, createdAt: date }),
    {
      enabled: inView,
    },
  );
  const { data: listData, isLoading: isListDataLoading } = useGetExerciseListQuery(
    {
      memberIdx,
      createdAt: date,
    },
    {
      enabled: inView,
    },
  );

  const setExerciseBasketList = useSetRecoilState(exerciseBasketListState);

  useEffect(() => {
    if (listData) {
      setExerciseBasketList(listData);
    }
  }, [date, listData, mainInfoData?.totalCalories, setExerciseBasketList]);

  if (isMainInfoDataLoading && isListDataLoading) return <SpinnerInBox height={'492px'} />;

  if (mainInfoData?.totalTime)
    return (
      <Container ref={ref} isWelcomeMissionUser={isWelcomeMissionUser}>
        <h2>
          오늘 내 운동 시간은<span className="question">?</span>
        </h2>
        <div>{convertTime({ num: mainInfoData.totalTime, type: 'main' })}</div>
        <CharacterImg src={ExerciseMainCharacter} />
        <dl>
          <dt>
            <Fire />
            소모량
          </dt>
          <dd>{mainInfoData.exerciseCalories}kcal</dd>
        </dl>
        <ThemeButton color={COLORS.PRIMITIVES_KHAKI_450}>
          <Link
            to={`/basket/exercise/list/${date}`}
            state={{ isBack: false }}
            onClick={onClickExerciseRecord}
          >
            기록 수정하기
          </Link>
        </ThemeButton>
      </Container>
    );
  return (
    <Container ref={ref} isWelcomeMissionUser={isWelcomeMissionUser}>
      <h2>
        오늘 내 운동 시간은<span className="question">?</span>
      </h2>
      <p>
        <span className="zero">0</span>
        <span>분</span>
      </p>
      <CharacterImg src={ExerciseMainCharacter} />
      <dl>
        <dt>
          <Fire />
          소모량
        </dt>
        <dd>0kcal</dd>
      </dl>
      <ThemeButton color={COLORS.PRIMITIVES_KHAKI_450}>
        <Link
          to={`/exercise/search?tab=빠른 추가`}
          state={{ isBack: false }}
          onClick={onClickExerciseRecord}
        >
          기록하기
        </Link>
      </ThemeButton>
    </Container>
  );
};
export default ExerciseDiary;
