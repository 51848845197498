import styled from 'styled-components';
import cx from 'classnames';
import usePostScroll from '@hooks/usePostScroll';

interface Props {
  children: React.ReactNode;
  upY: number;
  downY: number;
  bottom: number;
}

const ScrollUpDownWrapper = ({ children, upY, downY, bottom }: Props) => {
  const scrollState = usePostScroll();

  return (
    <Wrapper upY={upY} downY={downY} bottom={bottom} className={cx([scrollState])}>
      {children}
    </Wrapper>
  );
};

export default ScrollUpDownWrapper;

const Wrapper = styled.div<{ upY: number; downY: number; bottom: number }>`
  position: fixed;
  width: 100%;
  max-width: 428px;
  bottom: ${({ bottom }) => bottom}px;
  z-index: 500;

  &.up {
    transform: translateY(${({ upY }) => `${upY}px`});
    transition-duration: 300ms;
  }
  &.down {
    transform: translateY(${({ downY }) => `${downY}px`});
    transition-duration: 300ms;
  }
`;
