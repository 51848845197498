import { cx } from 'styles';
import { Container, ThemeBox } from './styles';
import TitleWrapper from './TitleWrapper';

import { Dispatch, PropsWithChildren, SetStateAction } from 'react';

import { ITabList } from 'types/tabList';

interface Props {
  mainTitle: string;
  bgColor: 'navy' | 'apricot' | 'khaki' | 'skyblue' | 'purple';
  tabIndex?: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
  tabList: ITabList[];
  isNew?: boolean;
  minHeight?: string;
  maxHeight?: string;
  paddingBottom?: string;
  id?: string;
}

const ThemeContainer = ({
  mainTitle,
  bgColor,
  tabIndex,
  setTabIndex,
  tabList,
  isNew,
  children,
  minHeight,
  maxHeight,
  paddingBottom,
  id,
}: PropsWithChildren<Props>) => {
  const clickCountHandler = (num: number) => {
    if (tabIndex === num) return;
    setTabIndex(num);
  };
  return (
    <Container
      className={cx([bgColor])}
      minHeight={minHeight}
      maxHeight={maxHeight}
      paddingBottom={paddingBottom}
    >
      <TitleWrapper text={mainTitle} padding isNew={isNew}>
        <ThemeBox>
          {tabList.map((item, index) => (
            <li
              key={item.id}
              className={cx([bgColor], { ['tabChecked']: tabIndex === index })}
              onClick={() => clickCountHandler(index)}
            >
              {item.name}
            </li>
          ))}
        </ThemeBox>
      </TitleWrapper>
      {children}
    </Container>
  );
};
export default ThemeContainer;
