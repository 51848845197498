import { useState, useEffect } from 'react';

const useImagePreloader = (imageSrc: string) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!imageSrc) return;

    const img = new Image();
    img.src = imageSrc;
    img.onload = () => setIsLoaded(true);
    img.onerror = () => setIsLoaded(false);
  }, [imageSrc]);

  return isLoaded;
};

export default useImagePreloader;
