import { getFoodDetail, modifyFoodDetail } from '@apis/searchApi';
import ButtonWrapper from '@components/Atoms/Button/buttonWrapper';
import Stepper from '@components/Stepper';
import BackArrowGnb from '@components/elements/BackArrowGnb';
import Popup from '@components/elements/Popup/Popup';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import useFunnel from '@hooks/useFunnel';
import useToastModal from '@hooks/useToastModal';
import useTopModal from '@hooks/useTopModal';
import { FoodDetail } from '@models/searchResult';
import { getPresignedUrl, uploadPresignedUrl } from '@modules/imgS3';
import ButtonSet from 'components/Atoms/Button/ButtonSet';
import Spinner from 'components/Spinner';
import ToastPageMsg from 'components/elements/ToastPageMsg';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';
import FoodEditImage from './FoodEditImage';
import FoodEditNutrient from './FoodEditNutrient';

const FoodEdit = () => {
  const { sendAdBrixData } = useAdBrix();
  const navigate = useNavigate();
  const { food_id, step } = useParams() as { food_id: string; step: string };
  const { memberIdx } = useUserInfoQuery();
  const { openModal: openTopModal, closeModal: closeTopModal } = useTopModal();

  const steps = ['nutrientInfo', 'image'] as readonly [string, ...string[]];
  const [Funnel, setStep] = useFunnel(steps);

  const [temp, setTemp] = useState<FoodDetail>({
    food_idx: '',
    food_name: '',
    food_brand: '',
    food_type: 'Brand',
    creator: 0,
    measurement_description: '',
    metric_serving_unit: '',
    metric_serving_amount: '1',
    number_of_units: 0,
    created_at: '',
    updated_at: '',
    calories: 0,
    carbohydrate: 0,
    sugar: 0,
    fiber: 0,
    protein: 0,
    fat: 0,
    saturated_fat: 0,
    monounsaturated_fat: 0,
    polyunsaturated_fat: 0,
    trans_fat: 0,
    sodium: 0,
    cholesterol: 0,
    potassium: 0,
    net_carbohydrate: 0,
    sugar_sub: 0,
    report: 0,
  });
  const [msg, setMsg] = useState('');
  const [images, setImages] = useState<{ index: number; file: File; path: string }[]>([
    { index: 0, file: new File([], ''), path: '' },
    { index: 1, file: new File([], ''), path: '' },
  ]);

  const [isToast, setIsToast] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const { openModal } = useToastModal();

  const { isLoading } = useQuery(
    ['#food', '#edit', { query: food_id }],
    () => getFoodDetail(food_id),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      keepPreviousData: true,
      onSuccess: (res) => {
        setTemp({
          ...res,
          calories: Math.round(res.calories * 10) / 10,
          metric_serving_amount: (
            Math.round(Number(res.metric_serving_amount ?? 0) * 10) / 10
          ).toString(),
        });
      },
    },
  );

  const { mutateAsync: patchMutateAsync } = useMutation({
    mutationFn: modifyFoodDetail,
    onSuccess: () => {
      sendAdBrixData('customEvent', {
        eventKey: 'request_food_edit',
      });
      closeTopModal(Popup);
      openModal({ children: <p>수정 요청이 완료되었어요</p> });
      navigate(-2);
      setIsMutating(false);
    },
    onError: () => {
      closeTopModal(Popup);
      openModal({ children: <p>수정 요청에 실패했어요</p> });
      setIsMutating(false);
    },
  });

  const isMetricZero = (() => {
    return Number(temp.metric_serving_amount ?? 0) === 0;
  })();

  const isRequired = (() => {
    return Boolean(
      !!temp.metric_serving_amount.toString() &&
        !!temp.calories.toString() &&
        !!temp.carbohydrate.toString() &&
        !!temp.protein.toString() &&
        !!temp.fat.toString(),
    );
  })();

  const isTotalCarboCalc = (() => {
    const total = Number(temp.sugar) + Number(temp.sugar_sub) + Number(temp.fiber);
    return Number(temp.carbohydrate) >= total;
  })();

  const isTotalFatCalc = (() => {
    const total =
      Number(temp.saturated_fat) +
      Number(temp.trans_fat) +
      Number(temp.polyunsaturated_fat) +
      Number(temp.monounsaturated_fat);

    return Number(temp.fat) >= total;
  })();

  const onClick = () => {
    if (isMetricZero) {
      setMsg('내용량은 0으로 입력할 수 없어요');
      setIsToast(true);
      return;
    }

    if (!isRequired) {
      setMsg('필수 정보를 입력해 주세요');
      setIsToast(true);
      return;
    }

    if (!isTotalCarboCalc) {
      setMsg(`당, 대체 감미료, 식이섬유의 합은\n 탄수화물 총량을 넘길 수 없어요`);
      setIsToast(true);
      return;
    }

    if (!isTotalFatCalc) {
      setMsg(`기타 지방의 합은\n 지방 총량을 넘길 수 없어요`);
      setIsToast(true);
      return;
    }
    navigate(`/custom/image/${food_id}`);
    return;
  };

  const imgUploadS3 = async (file: File) => {
    const data = await getPresignedUrl({
      memberIdx,
      body: {
        path: 'food-report',
        count: 1,
        file_format: file.type.split('/')[1],
      },
    });
    const url = await uploadPresignedUrl({ url: data[0], blob: file });

    return url;
  };

  const getPaths = async () => {
    try {
      const paths: string[] = [];
      for (const img of images) {
        const path = await imgUploadS3(img.file);
        if (!path) return;
        paths.push(path);
      }
      return paths;
    } catch (err) {
      setMsg('이미지를 다시 업로드해 주세요');
      setIsToast(true);
    }
  };

  const onUpload = async () => {
    try {
      setIsMutating(true);
      const paths = await getPaths();
      if (!paths) return;
      if (paths.length === 2) {
        await patchMutateAsync({ member_idx: memberIdx, food: temp, paths });
      }
    } catch (err) {
      setMsg('요청을 다시 해주세요');
      setIsToast(true);
    }
  };

  const onConfirm = () => {
    const isTwoImagesComplete = images.every((item) => item.path !== '');

    if (!isTwoImagesComplete) {
      setMsg('2장의 사진을 모두 올려주세요');
      setIsToast(true);
      return;
    }
    openTopModal(Popup, {
      headText: '영양정보 수정을 요청하시겠어요?',
      subText1: '관리자 승인 후 반영되며, ',
      subText2: '승인까지 1~2일이 소요됩니다.',
      buttonText1: '다음에',
      buttonText2: '네.좋아요!',
      onClick: onUpload,
      onClose: () => closeTopModal(Popup),
      cancel: () => closeTopModal(Popup),
    });
  };

  const bottomButton = {
    nutrientInfo: (
      <ButtonWrapper>
        <ButtonSet
          text="다음"
          size="long"
          type="button"
          color={isRequired ? 'black' : 'isInActive'}
          isActive={isRequired}
          onClick={onClick}
        />
      </ButtonWrapper>
    ),
    image: (
      <ButtonWrapper infoText={'정보 확인이 어려울 경우 반려될 수 있어요'}>
        <ButtonSet
          text="수정 요청하기"
          size="long"
          type="button"
          color="black"
          isActive={!isMutating}
          onClick={onConfirm}
        />
      </ButtonWrapper>
    ),
  }[step];

  useEffect(() => {
    setStep(step);
  }, [step]);

  if (isLoading) {
    return <Spinner onSpinnerState={isLoading} />;
  }

  return (
    <>
      {isMutating && (
        <Spinner onSpinnerState={isMutating} zIndex={99999} dimmedColor="rgba(0,0,0,0)" />
      )}
      <Container>
        <ToastPageMsg onToast={isToast} setOnToast={setIsToast}>
          {msg}
        </ToastPageMsg>

        <Header>
          <BackArrowGnb onClick={() => navigate(-1)} />
        </Header>
        <Stepper
          focusedIndex={Number(steps.findIndex((name) => name === step) + 1)}
          length={2}
          unfixed
          padding={'20px 0 20px 20px'}
        />

        <Body isMoreBottom={step === 'image'}>
          <Funnel>
            <Funnel.Step name="nutrientInfo">
              <FoodEditNutrient temp={temp} setTemp={setTemp} />
            </Funnel.Step>
            <Funnel.Step name="image">
              <FoodEditImage images={images} setImages={setImages} />
            </Funnel.Step>
          </Funnel>
        </Body>

        {bottomButton}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background: ${COLORS.WHITE};
`;

const Header = styled.header`
  padding-bottom: 50px;
`;

const Body = styled.div<{ isMoreBottom: boolean }>`
  padding: ${({ isMoreBottom }) => (isMoreBottom ? '0 20px 114px' : '0 20px 78px')};
`;

export default FoodEdit;
