import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import DeleteDefaultButton from '../Molecules/DeleteDefaultButton';

interface Props {
  backgroundColor?: string;
  color?: string;
  onClick(): void;
  relative?: boolean;
  absolute?: boolean;
  text?: string;
  size?: 'large' | 'semiLarge' | 'medium' | 'mediumBold' | 'small' | 'smallBold';
  padding?: string;
}

export default function CloseXGnb({
  backgroundColor = '#fff',
  color = '#000',
  size = 'large',
  onClick,
  relative,
  absolute,
  text,
  padding = '0 11px',
}: Props) {
  const classes = useStyles({ padding });

  return (
    <Grid
      container
      alignItems={'center'}
      className={classNames([
        classes.closeHeader,
        relative && classes.notFixed,
        absolute && classes.absolute,
      ])}
      style={backgroundColor ? { background: backgroundColor } : {}}
    >
      <DeleteDefaultButton onClick={onClick} color={color} size={size} />
      <p className={classes.boardHeader}>{text}</p>
    </Grid>
  );
}

const useStyles = makeStyles<Theme, { padding: string }>(() =>
  createStyles({
    closeHeader: {
      maxWidth: 428,
      position: 'fixed',
      zIndex: 2101,
      top: 0,
      height: 50,
      background: '#fff',
      padding: ({ padding }) => padding,
      // '& div': {
      //   width: 24,
      //   height: 24,
      // },
    },

    notFixed: {
      position: 'relative',
    },
    absolute: {
      position: 'absolute',
    },
    boardHeader: {
      position: 'absolute',
      top: 18,
      left: 0,
      width: '100%',
      height: 14,
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: -0.54,
      textAlign: 'center',
      zIndex: -1,
    },
  }),
);
