import {
  DessertIcon,
  MealTimeBreakfastIcon,
  MealTimeLunchIcon,
  MealTimeDinnerIcon,
  MealTimeBasketBreakfastIcon,
  MealTimeBasketLunchIcon,
  MealTimeBasketDinnerIcon,
  MealTimeBasketDessertIcon,
} from '@assets/svgs/food';

interface Props {
  mealTime: number;
}

export const MealTimeIcon = ({ mealTime }: Props) => {
  const icon = {
    0: <MealTimeBreakfastIcon />,
    1: <MealTimeLunchIcon />,
    2: <MealTimeDinnerIcon />,
    3: <DessertIcon />,
  }[mealTime];
  return <>{icon}</>;
};

export const MealTimeBasketIcon = ({ mealTime }: Props) => {
  const icon = {
    0: <MealTimeBasketBreakfastIcon />,
    1: <MealTimeBasketLunchIcon />,
    2: <MealTimeBasketDinnerIcon />,
    3: <MealTimeBasketDessertIcon />,
  }[mealTime];
  return <>{icon}</>;
};
