import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dateState } from 'states/DateState';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// components
import { Container } from '@material-ui/core';
import { timeZoneState } from '@pages/fasting/new/state';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import useGetDailyMissionQuery from '@pages/apple/hooks/useGetDailyMissionQuery';
import { COLORS } from '@styles/constants/_colors';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { DailyMissionListType, MealRecordType } from '@models/point';
import useAdBrix from '@hooks/adBrix/useAdBrix';

// assets
import AlbumIcon from '@assets/images/png/AlbumIcon.png';
import AppleIcon from '@assets/images/rewards/applePointIcon.png';
import RewardStar from '@assets/images/png/appleRewardStar.png';
import RewardBubble from '@assets/images/png/appleRewardBubble.png';

const DietDiaryFooter = () => {
  const { sendAdBrixData } = useAdBrix();
  const [date] = useRecoilState(dateState);
  const navigate = useNavigate();
  const timezone = useRecoilValue(timeZoneState);
  const { memberIdx } = useUserInfoQuery();
  const { data } = useGetDailyMissionQuery(timezone, memberIdx);
  const [possibleReward, setPossibleReward] = useState(0);

  const findDietRecordRewards = (data: DailyMissionListType) => {
    const dietRecord = data?.['식단 기록'];
    let reward = 0;
    Object.entries(dietRecord).forEach(([key, value]) => {
      const mealRecord = value as MealRecordType;
      if (mealRecord.recorded && !mealRecord.rewarded) {
        reward += mealRecord.amount;
      }
    });

    setPossibleReward(reward);
  };

  useEffect(() => {
    if (!data) return;
    findDietRecordRewards(data);
  }, [data]);

  return (
    <Container>
      <Footer>
        <HalfBox>
          <AppleRewardBox>
            {possibleReward !== 0 && (
              <>
                <RewardBubbleBox>
                  <img src={RewardBubble} style={{ width: '120px', height: '30px' }} />
                </RewardBubbleBox>
                <RewardBubbleContent>지금 {possibleReward}개 받기 가능! </RewardBubbleContent>
              </>
            )}

            <ContentButton
              onClick={() => {
                sendAdBrixData('customEvent', {
                  eventKey: `click_meal_mission_quick`,
                });
                navigate('/apple/point?menu=일일미션', { state: { scrollTo: 'diet-record' } });
              }}
            >
              {possibleReward !== 0 && (
                <img
                  src={RewardStar}
                  style={{
                    position: 'absolute',
                    width: '40px',
                    height: '11px',
                    top: 0,
                    left: -6.5,
                  }}
                />
              )}
              <img src={AppleIcon} style={{ marginRight: '12px', width: '24px', height: '24px' }} />
              <div>기록 보상</div>
            </ContentButton>
          </AppleRewardBox>
        </HalfBox>

        <Division />
        <HalfBox>
          <ContentButton
            onClick={() => {
              sendReactNativeMessage({
                type: 'food-album-gallery-open',
                payload: {
                  selectedDate: date,
                },
              });
            }}
          >
            <img src={AlbumIcon} style={{ marginRight: '12px', width: '24px', height: '24px' }} />
            <div>식단 앨범</div>
          </ContentButton>
        </HalfBox>
      </Footer>
    </Container>
  );
};

export default DietDiaryFooter;

const Footer = styled.div`
  position: relative;
  width: 100%;
  display: inline-flex;
  padding: 13px 0 14px 0;
  align-items: center;
  border-radius: 120px;
  background: ${COLORS.PRIMITIVES_BLUE_NAVY_450};
`;

const HalfBox = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
`;

const AppleRewardBox = styled.div`
  position: relative;
`;

const RewardBubbleBox = styled.div`
  position: absolute;
  width: 120px;
  height: 30px;
  top: -37px;
  left: -15px;
`;

const RewardBubbleContent = styled.div`
  position: absolute;
  width: 120px;
  height: 30px;
  top: -30px;
  left: -5px;
  z-index: 10;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 13px */
  letter-spacing: -0.5px;
  color: ${COLORS.PRIMITIVES_BLUE_NAVY_400};
`;

const ContentButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.5px;
  color: ${COLORS.WHITE};
  width: 91px;
`;

const Division = styled.div`
  width: 1.5px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 12px;
`;
