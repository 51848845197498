import { getWelcomeMissionConfig } from '@apis/profileApi';
import { timeZoneState } from '@pages/fasting/new/state';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';

const useGetWelcomeMissionQuery = (memberIdx: number, enabled: boolean) => {
  const timezone = useRecoilValue(timeZoneState);

  return useQuery({
    queryKey: ['welcomeMission', memberIdx],
    queryFn: () => getWelcomeMissionConfig(memberIdx, timezone),
    enabled,
    suspense: true,
    retry: false,
  });
};

export default useGetWelcomeMissionQuery;
