import { getDailyMission } from '@apis/pointApi';
import { useQuery } from 'react-query';

const useGetDailyMissionQuery = (timezone: string, memberIdx: number) => {
  return useQuery({
    queryKey: ['point', 'dailyMission', memberIdx, timezone],
    queryFn: () => getDailyMission({ memberIdx, timezone }),
    suspense: true,
  });
};

export default useGetDailyMissionQuery;
