import Portal from '@components/portal';
import { COLORS } from '@styles/constants/_colors';
import LEVEL from '@styles/constants/_levels';
import { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { swipeUp } from '@styles/mixins/_animations';
import CloseXGnb from '@components/elements/CloseGnb';
import WelcomeMissionCheckImg from '@assets/images/modal/welcomeMissionCheck.png';
import { WelcomeMissionConfigType } from '@models/profile';
import { CircleCheckEditIcon, CircleCheckStrongIcon } from '@assets/svgs';

interface Props {
  data?: WelcomeMissionConfigType;
  onClose: () => void;
  onSubmit: () => void;
}

interface MissionCheckCardProps {
  isComplete?: boolean;
  num: number;
}

const MissionCheckCard = ({ isComplete, num }: MissionCheckCardProps) => {
  return (
    <MissionCheckCardWrapper isComplete={isComplete}>
      <IconBox>{isComplete ? <CircleCheckStrongIcon /> : <CircleCheckEditIcon />}</IconBox>
      <NumText>
        <span>{num}</span>번
      </NumText>
    </MissionCheckCardWrapper>
  );
};

const WelcomeMissionCheckModal = ({ data, onClose, onSubmit }: Props) => {
  const count = data?.count ?? 0;

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <Portal>
      <Wrapper>
        <CloseXGnb onClick={onClose} size="semiLarge" padding="0px 20px" text="웰컴 미션" />
        <Field>
          <Title>
            <span>딱 3번만</span> 식단 기록하면{'\n'}꾸미기 아이템 <span>무료 지급!</span>
          </Title>
          <Text>
            <span>
              {data?.end_day.split('-')[1]}월 {data?.end_day.split('-')[2]}일
            </span>
            까지만 참여할 수 있어요
          </Text>
          <CheckCardField>
            {Array.from({ length: 3 }).map((_, index) => (
              <MissionCheckCard key={index} isComplete={index < count} num={index + 1} />
            ))}
          </CheckCardField>
          <Img src={WelcomeMissionCheckImg} />
          <ButtonBox>
            <Button isComplete={!(count < 3)} onClick={onSubmit}>
              {count < 3 ? '식단 기록하러 가기' : '미션 보상 받기'}
            </Button>
          </ButtonBox>
        </Field>
      </Wrapper>
    </Portal>
  );
};

export default WelcomeMissionCheckModal;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 428px;
  z-index: ${LEVEL.SECURITY_MODAL};
  padding-top: 50px;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  background-color: ${COLORS.WHITE};
  animation: ${swipeUp} 0.5s ease-in-out;
`;

const Field = styled.div`
  position: relative;
  width: 100%;
  height: min-content;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${COLORS.PRIMITIVES_GRAY_70};
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
`;

const Title = styled.p`
  position: absolute;
  top: 38px;
  font-family: Cafe24 Ssurround;
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: -0.5px;
  text-align: center;
  white-space: pre-wrap;
  color: ${COLORS.WHITE};

  span {
    color: #ffd771;
  }
`;

const Text = styled.p`
  position: absolute;
  top: 120px;
  font-family: Cafe24 Ssurround;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${COLORS.WHITE};

  span {
    color: #ffd771;
  }
`;

const ButtonBox = styled.div`
  position: fixed;
  bottom: 0px;
  padding: 12px 20px;
  width: 100%;
  max-width: 428px;
  background-color: ${COLORS.WHITE};
`;

const Button = styled.button<{ isComplete?: boolean }>`
  width: 100%;
  height: 54px;
  font-family: Cafe24 Ssurround;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.6px;
  text-align: center;
  color: ${COLORS.WHITE};
  background-color: ${({ isComplete }) => (isComplete ? '#FF9913' : COLORS.BLACK)};
  border-radius: 120px;
`;

const CheckCardField = styled.div`
  width: 100%;
  max-width: 428px;
  padding: 0px 12%;
  display: flex;
  column-gap: calc(14 / 375 * 100vw);
  position: absolute;
  top: calc(522 / 375 * 100vw);
`;

const MissionCheckCardWrapper = styled.div<{ isComplete?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: calc(6 / 375 * 100vw);
  flex: 1 1 calc(86 / 375 * 100vw);
  aspect-ratio: 86 / 89;
  border-radius: 16px;
  background-color: ${({ isComplete }) =>
    isComplete ? COLORS.PRIMITIVES_BLUE_100 : COLORS.PRIMITIVES_GRAY_60};
  color: ${({ isComplete }) => (isComplete ? COLORS.BLUE : COLORS.PRIMITIVES_GRAY_400)};
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(32 / 375 * 100vw);
  height: calc(32 / 375 * 100vw);
`;

const NumText = styled.p`
  font-family: Noto Sans KR;
  font-size: calc(14 / 375 * 100vw);
  font-weight: 500;

  span {
    font-family: Campton;
    font-size: calc(15 / 375 * 100vw);
    font-weight: 500;
  }
`;
