import {
  AccumulateMissionListType,
  DailyMissionListType,
  PointHistoryListType,
  PointType,
  RandomBoxRewardErrorType,
  RandomBoxRewardSuccessType,
} from '@models/point';
import { get, post } from '@modules/HttpWebClient/default';

// 일일 미션 현황
export async function getDailyMission({
  memberIdx,
  timezone,
}: {
  memberIdx: number;
  timezone: string;
}) {
  const url = `/v2/members/${memberIdx}/point/daily?timezone=${timezone}`;

  const { data } = await get<DailyMissionListType>(url);
  return data;
}

// 일일 미션 - 보상 받기 - 출석 체크
export async function postAttendanceCheckComplete({
  memberIdx,
  timezone,
}: {
  memberIdx: number;
  timezone: string;
}) {
  const url = `/v2/members/${memberIdx}/point/daily/출석 체크?timezone=${timezone}`;
  const { data } = await post(url);

  return data;
}

// 일일 미션 - 보상 받기 - 대나무숲 3회 댓글 쓰기
export async function postForestCommentComplete({
  memberIdx,
  timezone,
}: {
  memberIdx: number;
  timezone: string;
}) {
  const url = `/v2/members/${memberIdx}/point/daily/대나무숲에 3회 댓글 쓰기?timezone=${timezone}`;
  const { data } = await post(url);

  return data;
}

// 일일 미션 - 보상 받기 - 피드 3회 댓글 쓰기
export async function postFeedCommentComplete({
  memberIdx,
  timezone,
}: {
  memberIdx: number;
  timezone: string;
}) {
  const url = `/v2/members/${memberIdx}/point/daily/피드에 3회 댓글 쓰기?timezone=${timezone}`;
  const { data } = await post(url);

  return data;
}

// 일일 미션 - 보상 받기 - 식단기록하기
export async function postDietRecordComplete({
  memberIdx,
  timezone,
  type,
}: {
  memberIdx: number;
  timezone: string;
  type: string;
}) {
  const url = `/v2/members/${memberIdx}/point/daily/${type} 식단 기록?timezone=${timezone}`;
  const { data } = await post(url);

  return data;
}

// 일일 미션 - 보상 받기 - 광고 볼 때마다 사과 받기
export async function postAdsComplete({
  memberIdx,
  timezone,
}: {
  memberIdx: number;
  timezone: string;
}) {
  const url = `/v2/members/${memberIdx}/point/daily/광고 볼 때마다 사과 받기?timezone=${timezone}`;
  const { data } = await post(url);

  return data;
}

// 누적 미션 현황
export async function getAccumulateMission(memberIdx: number) {
  const url = `/v2/members/${memberIdx}/point/acc`;
  const { data } = await get<AccumulateMissionListType>(url);

  return data;
}

// 스티커 획득
export async function postAccumulateSticker({
  memberIdx,
  kind,
  timezone,
}: {
  memberIdx: number;
  kind: string;
  timezone: string;
}) {
  const url = `/v2/members/${memberIdx}/point/acc/${kind}?timezone=${timezone}`;
  await post(url);

  return kind;
}

// 포인트 현황
export async function getPoint(memberIdx: number) {
  const url = `/v2/members/${memberIdx}/point`;
  const { data } = await get<PointType>(url);

  return data;
}

// 포인트 내역
export async function getPointHistoryList(memberIdx: number, deposit?: boolean) {
  const url = `/v2/members/${memberIdx}/point/history`;
  const { data } = await get<PointHistoryListType[]>(url, {
    params: {
      deposit,
    },
  });

  return data;
}

// 랜덤박스(출석체크 대용)
export async function postRandomBox({
  memberIdx,
  timezone,
  type = 'attendance',
}: {
  memberIdx: number;
  timezone: string;
  type?: string;
}) {
  const url = `/v2/members/${memberIdx}/random-box/reward?timezone=${timezone}&type=${type}`;
  const { data } = await post<RandomBoxRewardSuccessType & RandomBoxRewardErrorType>(url);

  return data;
}
