import styled from 'styled-components';
import { COLORS } from 'styles/constants/_colors';

const Container = styled.article<{ isWelcomeMissionUser?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ isWelcomeMissionUser }) =>
    isWelcomeMissionUser ? '52px 20px 90px' : '52px 20px 60px'};
  gap: 12px;
  color: ${COLORS.WHITE};

  h2 {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: -6px;
    letter-spacing: -0.5px;

    .question {
      font-size: 18px;
    }
  }

  p {
    font-weight: 600;
    font-size: 18px;
    .zero {
      font-size: 50px;
    }
  }

  dl {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 4px 0 24px;
    dt {
      display: flex;
      gap: 4px;
      font-weight: 700;
      font-size: 16px;
    }
    dd {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: -1px;
    }
  }
`;

const ThemeButton = styled.button<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 255px;
  height: 60px;
  border-radius: 120px;
  background-color: ${(props) => props.color};

  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 6px;
    font-size: 15px;
    font-weight: 700;
    color: ${COLORS.WHITE};
    letter-spacing: -0.5px;
  }
`;

const CharacterImg = styled.img`
  width: 335px;
  height: 247px;
  object-fit: contain;
`;

export { Container, ThemeButton, CharacterImg };
