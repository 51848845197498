import Tail from '@assets/svgs/Tail';
import { COLORS } from '@styles/constants/_colors';
import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  backgroundColor?: string;
  tailType?: 'default' | 'triangle';
  padding?: string;
}

const SpeechBubble = ({
  children,
  backgroundColor = COLORS.PRIMITIVES_GRAY_100,
  tailType = 'default',
  padding = '7px 14px 9px',
}: Props) => {
  return (
    <BubbleWrapper>
      <BubbleBody bgColor={backgroundColor} padding={padding}>
        {children}
      </BubbleBody>
      <TailBox>{<Tail type={tailType} bgColor={backgroundColor} />}</TailBox>
    </BubbleWrapper>
  );
};

export default SpeechBubble;

const BubbleWrapper = styled.div`
  width: fit-content;
  position: relative;
`;

const BubbleBody = styled.div<{ bgColor: string; padding: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 120px;
  padding: ${({ padding }) => padding};
  background-color: ${({ bgColor }) => bgColor};
`;

const TailBox = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  position: absolute;
  bottom: -4px;
`;
